import { ConfigModuleFactory } from './config/factory.config.js';
import { getLanguageFromDocument } from './modules/langUtil.js';
import { initCDTSPageFinalized } from './modules/onCDTSPageFinalized.js';
import { getSiteForConfig, getTemplate } from './modules/site.js';

window.templateFrontEnd = {
  ready: false,
  isDebug: true,
  site: getSiteForConfig(window.location.href) ?? '',
  lang: getLanguageFromDocument(),
  template: getTemplate(),
  modules: {
    template: {
      adobeAnalytics: false,
      breadcrumbs: false,
      exportToWord: false,
      onCDTSPageFinalized: false
    },
    ['esdc-prv']: {
      articles: false,
      blogs: false,
      caroussel: false,
      catquery: false,
      moreNewsfeed: false,
      newsfeed: false,
      randomSurvey: false,
      shareThisPageBtn: false
    },
    ['iservice-prv']: {
      shareThisPageBtn: false,
      feedbackBtn: false
    }
  }
};

const site = window.templateFrontEnd.site;
const lang = window.templateFrontEnd.lang;
const template = window.templateFrontEnd.template;

if (site != null && lang != null && template != null) {
  const config = ConfigModuleFactory.getConfig(site);
  initCDTSPageFinalized(lang, template, config);
  if (config) config.dataCDTSSetup(lang, template);
} else {
  console.error('No config file found for this site');
}
